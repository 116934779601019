import { create } from "zustand";
import { verifyAuth } from "../utils/api/VerifyAuth";
import { initializeUserflow, resetUserflow } from "../utils/userflowUtils";
import { useDevicePermissionsStore } from "./DevicePermissionsStore";
import { useSessionStore } from "./SessionStore";

interface State {
  verifyAuthOngoing: boolean;
  userflowInitialised: boolean;
  userflowToken: string;
  userflowUserIdentity: string;
  userflowCompanyIdentity: string;
}

interface Actions {
  verifyAuth(): void;

  initializeUserflow(): void;

  resetUserflow(): void;
}

export const useUserflowStore = create<State & Actions>((set, get) => ({
  verifyAuthOngoing: false,
  userflowInitialised: false,
  userflowToken: "",
  userflowUserIdentity: "",
  userflowCompanyIdentity: "",

  verifyAuth: () => {
    if (get().verifyAuthOngoing) {
      return;
    }

    set(() => ({ verifyAuthOngoing: true }));
    void verifyAuth((data) => {
      set(() => ({
        userflowToken: data.userflowToken,
        userflowUserIdentity: data.userflowUserIdentity,
        userflowCompanyIdentity: data.userflowCompanyIdentity,
        verifyAuthOngoing: false,
      }));
    }).then(() => {
      if (get().userflowInitialised) {
        // Userflow already initialised, no need to do it again
        return;
      }

      if (useDevicePermissionsStore.getState().loaded) {
        // Device permissions already loaded, initialising userflow
        get().initializeUserflow();
        return;
      }

      // There is a race condition where the role permissions are not loaded yet, when we try to initialize Userflow.
      // We need to wait for the permissions to be loaded before initializing Userflow.
      useDevicePermissionsStore.subscribe((state) => {
        if (state.loaded) {
          // Device permissions now loaded after store subscription, initialising userflow
          get().initializeUserflow();
        }
      });
    });
  },

  initializeUserflow: () => {
    const { userflowToken, userflowUserIdentity, userflowCompanyIdentity } = get();

    if (!userflowToken || !userflowUserIdentity || !userflowCompanyIdentity) {
      console.log("Userflow not available");
      return;
    }

    const device = useSessionStore.getState().getDevice();
    const user = useSessionStore.getState().getUser();
    const permissions = useDevicePermissionsStore.getState().permissions;

    void initializeUserflow(
      device.id,
      device.name,
      device.region,
      device.timezone,
      permissions,
      user.username,
      userflowToken,
      userflowUserIdentity,
      userflowCompanyIdentity
    ).then(() => {
      set(() => ({
        userflowInitialised: true,
      }));
    });
  },

  resetUserflow() {
    if (!get().userflowInitialised) {
      return;
    }

    resetUserflow();
    set(() => ({
      verifyAuthOngoing: false,
      userflowInitialised: false,
      userflowToken: "",
      userflowUserIdentity: "",
      userflowCompanyIdentity: "",
    }));
  },
}));
