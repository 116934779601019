import React, { useEffect } from "react";
import SessionStore from "../stores/SessionStore";
import { useSessionStore } from "../storez/SessionStore";

const ForethoughtAI: React.FC = () => {
  const removeForethoughtAIChatbot = () => {
    document.getElementById("forethought-chat")?.remove();
    document.getElementById("forethought-widget-embed-script")?.remove();
  };

  const sessionChanged = () => {
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    if (!SessionStore.getSelectedDevice() || !SessionStore.isAuthenticated()) {
      removeForethoughtAIChatbot();
    }
  };

  const getLocale = (region: string, timezone: string): string => {
    switch (region) {
      case "us-1":
        if (timezone.includes("Pacific")) {
          return "en-NZ";
        }
        return "en-US";
      case "syd-2":
        return "en-AU";
      case "uk-1":
        return "en-GB";
      default:
        return "en-US";
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_FORETHOUGHT_AI_API_KEY) {
      // The following script is available in the ForethoughtAI dashboard
      // ForethoughtAI app link: https://qoria.forethought.app/
      document.querySelector("#forethought-chat")?.remove();
      const script = document.createElement("script");
      script.setAttribute("src", "https://solve-widget.forethought.ai/embed.js");
      script.setAttribute("id", "forethought-widget-embed-script");
      script.setAttribute("data-api-key", process.env.REACT_APP_FORETHOUGHT_AI_API_KEY);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      script.setAttribute("data-ft-Device-Id", useSessionStore.getState().getDevice().id || "");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      script.setAttribute("data-ft-Email", useSessionStore.getState().getUser().username || "");
      //script.setAttribute("data-ft-Full-Name", ""); // SM does not have access to the user's full name.
      script.setAttribute(
        "data-ft-Locale",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        getLocale(useSessionStore.getState().getDevice()?.region, useSessionStore.getState().getDevice()?.timezone)
      );
      script.setAttribute("data-ft-workflow-tag", "SchoolManager");
      document.head.append(script);
    }
    return () => {
      removeForethoughtAIChatbot();
    };
  }, []);

  useEffect(() => {
    SessionStore.listen(sessionChanged);
    return () => {
      SessionStore.unlisten(sessionChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="forethoughtAIChatbot"></div>;
};

export default ForethoughtAI;
