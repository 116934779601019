import { Box, Flex, Td, Text, Tr, useTranslation, Option, useToast, Icon } from "@familyzone/component-library";
import Link from "../Link";
import React, { useEffect, useRef, useState } from "react";

import InfoBox from "../../modules/InfoBox";
import { TableColumn } from "../../types/table";
import TableWithChartsPage from "../templates/TableWithChartsPage";
import {
  formatData,
  FormattedData,
  getAppOrWebsiteLink,
  getReportingFilter,
  getTopReportingItems,
  ReportingFilter,
  ReportingTopTypesDataResponse,
  serialiseReportingFilters,
  validateTrafficAndWebsiteTotalResponse,
} from "./ReportingTopTypesHelper";
import { getInfoBoxProps, InfoBoxProps } from "../../modules/InfoBoxHelpers";
import { CSVExportButton } from "../../modules/ExportReport";
import StudentNamePill from "../../modules/StudentNamePill";
import { GroupSearchSelector } from "../GroupSearch/GroupSearchSelector";
import { GroupOption } from "../../types/Groups";
import Api from "../../utils/Api";
import { InputChartDataItem, TopSixAppsPieChart } from "../TopSixAppsPieChart";
import GroupNamePill from "../../modules/GroupNamePill";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";

const ReportingTopTypesNew = (): JSX.Element => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Apps and Websites"), url: "/surfwize/reporting/types", isActive: true },
  ];

  const { errorToast } = useToast();

  const selectedGroup = useRef<GroupOption | null>(null);
  const [topItems, setTopItems] = useState<InputChartDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formattedData, setFormattedData] = useState<FormattedData[]>([]);
  // NOTE: window.location.search will not return the correct results when hashHistory is used with react-router (currently set during local dev)
  // see AppRoot.js
  const [filter, setFitler] = useState<ReportingFilter>(getReportingFilter(new URLSearchParams(window.location.search)));

  const onGroupSelection = (selected: Option | null): void => {
    // If we have already cleared the selector, no need to refresh (and make another network call)
    if (!selected && !selectedGroup.current) {
      return;
    }

    selectedGroup.current = selected;
    const groupName = selectedGroup.current && selectedGroup.current.hasOwnProperty("name") ? selectedGroup.current.name : undefined;

    // DQS does support sending the group uuid as `filter_group_id`, but we need to update lwutils.linewize_api_base_client to support passing this param through
    // before we can provide that to DQS.
    setFitler({ ...filter, filter_group: groupName });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
    const unsubFilterStore = useDateRangeFilterStore.subscribe(onDateChanged);

    return () => {
      unsubFilterStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setting the start/end date/times is handled by Api.get_analytics (see section under url.indexOf("overrideDates") === -1)
  // so we just need to trigger a refetch if the FilterStore has changes.
  const onDateChanged = () => {
    setFitler({
      ...filter,
    });
  };

  useEffect(() => {
    setLoading(true);
    Api.get_analytics(
      "/surfwize/analytics/traffic/typesandwebsites?" + serialiseReportingFilters(filter),
      function (result: ReportingTopTypesDataResponse) {
        setLoading(false);
        if (!validateTrafficAndWebsiteTotalResponse(result)) {
          errorToast({ title: "There was an error fetching reporting data", isClosable: true });
        } else {
          setFormattedData(result.data.map(formatData));
          setTopItems(getTopReportingItems(result));
        }
      },
      function (_: unknown) {
        setLoading(false);
        errorToast({ title: "There was an error fetching reporting data", isClosable: true });
      }
    );
  }, [errorToast, filter]);

  const dataMap = ({ appOrWebsite, displayUpload, displayCategory, displayDownload, displayTotal, raw }: FormattedData, index: number) => {
    const infoBoxProps: InfoBoxProps = getInfoBoxProps(raw, filter?.filter_user);
    const link = getAppOrWebsiteLink(raw, serialiseReportingFilters(filter), filter?.filter_user);
    return (
      <Tr key={index}>
        <Td data-testid="category-cell">
          <Text fontSize="md">{displayCategory}</Text>
        </Td>
        <Td data-testid="appOrWebsite-cell">
          <Flex>
            <Link to={link}>
              <Text fontSize="md">{appOrWebsite ? appOrWebsite : "-"}</Text>
            </Link>
            {appOrWebsite && infoBoxProps.type === ("signature" || "category") && (
              <InfoBox
                type={infoBoxProps.type}
                category={infoBoxProps.category}
                target={infoBoxProps.target}
                signatureId={infoBoxProps.signatureId}
              />
            )}
            {/* This is not type === website as for some reason the type fields are showing some websites are none or categories */}
            {appOrWebsite && infoBoxProps.type !== ("signature" || "category") && (
              <Flex pl="sp8">
                <Link
                  href={`http://${appOrWebsite}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={""}
                  title={`Open ${appOrWebsite} in new tab`}
                  data-testid="websiteLink"
                >
                  <Icon icon="fa-sharp fa-light fa-arrow-up-right-from-square" variant="solid" mr="sp8" />
                </Link>
              </Flex>
            )}
          </Flex>
        </Td>
        <Td>
          <Text fontSize="md">{displayUpload}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{displayDownload}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{displayTotal}</Text>
        </Td>
      </Tr>
    );
  };

  const columnTitles = ["Category", "Application or Website", "Upload", "Download", "Total transfer"];
  const columnNames = ["displayCategory", "appOrWebsite", "upload", "download", "total"];

  const columns: TableColumn[] = [];
  columnTitles.forEach((item, index) => {
    columns.push({
      headerText: t(item),
      columnName: columnNames[index],
      sortable: true,
      searchable: true,
      exportable: true,
    });
  });

  const Children: React.FC = () => {
    if (filter && (filter.filter_user || filter.filter_group)) {
      return (
        <Flex>
          {filter.filter_user && (
            <Box marginTop="24" marginLeft="24">
              <StudentNamePill username={filter.filter_user} />
            </Box>
          )}
          {filter.filter_group && (
            <Box marginTop="24" marginLeft="12">
              <GroupNamePill groupName={filter.filter_group} />
            </Box>
          )}
        </Flex>
      );
    } else return <></>;
  };

  return (
    <TableWithChartsPage
      title={t("Apps and Websites")}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={formattedData}
      tableDataMap={dataMap}
      loaded={!loading}
      children={<Children />}
      childrenInTableHeader={
        <Flex>
          <GroupSearchSelector
            showHelpText={false}
            placeholderText={"Filter by Group"}
            handleChange={onGroupSelection}
          ></GroupSearchSelector>
          <Box ms="sp8">
            <CSVExportButton columns={columns} formattedData={formattedData} />
          </Box>
        </Flex>
      }
      charts={<TopSixAppsPieChart data={topItems} dataLoaded={!loading} />}
    />
  );
};

export default ReportingTopTypesNew;
