import userflow from "userflow.js";

declare module "userflow.js" {
  export function init(token: string): void;

  export function identify(
    userId: string,
    userAttributes: {
      sm_email?: string;
      user_timezone: string;
      sm_user_onb_optOut: boolean;
      sm_user_rfresh_opOut: boolean;
      sm_user_nps: number;
      user_theme: string;
      sm_user_join: string;
      user_language: string;
    },
    userOptions?: { signature?: string }
  ): void;

  export function group(
    groupId: string,
    groupAttributes: {
      sm_appliance_name: string;
      sm_appliance_id: string;
      sm_region: string;
      sm_timezone: string;
    },
    groupOptions: {
      membership: {
        application: string;
        sm_role: string[];
        sm_config: string;
      };
      signature?: string;
    }
  ): void;

  export function reset(): void;
}

const getUserBrowserLanguage = (): string => {
  const nav = typeof navigator !== "undefined" ? navigator : ({} as Navigator);
  if ("language" in nav && typeof nav.language === "string") {
    return nav.language;
  } else {
    return "en-US";
  }
};

export const initializeUserflow = async (
  applianceId: string,
  applianceName: string,
  applianceRegion: string,
  applianceTimezone: string,
  appliancePermissions: string[],
  username: string,
  token: string,
  userSignature: string,
  groupSignature: string
): Promise<void> => {
  userflow.init(token);

  const userLanguage = getUserBrowserLanguage();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Variables used for below method userflow.identify()
  const userId = username;
  const userAttributes = {
    sm_email: username,
    user_timezone: userTimezone,
    user_language: userLanguage,
    sm_user_onb_optOut: { set_once: false },
    sm_user_rfresh_opOut: { set_once: false },
    sm_user_nps: { set_once: 0 },
    user_theme: { set_once: "N/A" },
    sm_user_join: { set_once: "N/A" },
  };
  const userOptions = { signature: userSignature };

  // Variables used for below method userflow.group()
  const groupId = applianceId;
  const groupAttributes = {
    sm_appliance_name: applianceName,
    sm_appliance_id: applianceId,
    sm_region: applianceRegion,
    sm_timezone: applianceTimezone,
  };
  const groupOptions = {
    membership: {
      application: { prepend: "School Manager" },
      sm_role: appliancePermissions,
      sm_config: { prepend: "Access" },
    },
    signature: groupSignature,
  };

  try {
    await userflow.identify(userId, userAttributes, userOptions);
    await userflow.group(groupId, groupAttributes, groupOptions);
  } catch (error) {
    console.error("Error initializing Userflow:", error);
  }
};

export function resetUserflow(): void {
  userflow.reset();
}
